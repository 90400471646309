import React from "react";
import "./certification-developpeur-web-and-solutions.css";
import {
  SuperBlockStages,
  SuperBlocks,
  superBlockOrder,
} from "../../../shared/config/superblocks";
import { useTranslation } from "react-i18next";
import { SuperBlockIcon } from "../assets/icons/superblock-icon";
import LinkButton from "../assets/icons/link-button";
import { Link, Spacer } from "../components/helpers";
import { getSuperBlockTitleForMap } from "../utils/superblock-map-titles";
import { getSuperBlockIntroForMap } from "../utils/superblock-map-intro";
import { showUpcomingChanges } from "../../config/env.json";
import Login from "../components/Header/components/login";
import LoginToSee from "../components/Header/components/logintosee";
import { useSelector } from "react-redux";
import { isSignedInSelector } from "../redux/selectors";
import img from "./ss.svg";

interface MapProps {
  forLanding?: boolean;
}

const linkSpacingStyle = {
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
};

const coreCurriculum = [
  //   ...superBlockOrder[SuperBlockStages.FrontEnd],
  //   ...superBlockOrder[SuperBlockStages.Backend],
  ...superBlockOrder[SuperBlockStages.Python],
  //   ...superBlockOrder[SuperBlockStages.Professional],
  //   ...superBlockOrder[SuperBlockStages.Legacy],
  //   ...superBlockOrder[SuperBlockStages.New],
];
function MapLi({
  superBlock,
  landing = false,
}: {
  superBlock: SuperBlocks;
  landing: boolean;
}) {
  const isSignedIn = useSelector(isSignedInSelector); // Get sign-in status from Redux

  // Get the title for the superBlock
  const superBlockTitle = getSuperBlockTitleForMap(superBlock);

  // Check if the title matches "A2 English for Developers (Beta) Certification"
  const isA2English =
    superBlockTitle === "A2 English for Developers (Beta) Certification";

  return (
    <li
      data-test-label="curriculum-map-button"
      className={`${isA2English ? "red-background" : "card"}`}
    >
      {isSignedIn ? (
        <Link className="card-link" to={`/learn/${superBlock}/`}>
          <div
            className={`${
              isA2English ? "card-container-red" : "card-container"
            }`}
          >
            <SuperBlockIcon className="map-icon" superBlock={superBlock} />
            <div className="card-body">
              <div className="card-title">{superBlockTitle}</div>
              <p className="card-text">
                {getSuperBlockIntroForMap(superBlock)}
              </p>
              <button
                className={`${
                  isA2English ? "details-button-red" : "details-button"
                }`}
              >
                See More Details
              </button>
            </div>
          </div>
        </Link>
      ) : (
        <div
          className={`${isA2English ? "card-container-red" : "card-container"}`}
        >
          <SuperBlockIcon className="map-icon" superBlock={superBlock} />
          <div className="card-body">
            <div
              className={`card-title ${isA2English ? "red-background" : ""}`}
            >
              {superBlockTitle}
            </div>
            <p className="card-text">{getSuperBlockIntroForMap(superBlock)}</p>
            <button
              className={`${
                isA2English ? "details-button-red" : "details-button"
              }`}
            >
              <LoginToSee />
            </button>
          </div>
        </div>
      )}
    </li>
  );
}

const DertificationDeveloppeurSecurite = () => {
  const { t } = useTranslation(); // Use the useTranslation hook inside the component

  return (
    <>
      <section className="intro-category-new container">
        <div className="content">
          <div className="left">
            <h1>Security Developer Certification</h1>
            <Spacer size="medium" />

            <p>
              The Security Developer Certification is designed for professionals
              who want to specialize in building secure software systems and
              applications. This certification covers the fundamental principles
              of cybersecurity, including encryption, authentication, secure
              coding practices, and vulnerability assessments. Participants will
              learn how to integrate security into every stage of the
              development lifecycle, from design and development to testing and
              deployment, ensuring their applications are protected against
              cyber threats. <br />
              <br />
              In addition to hands-on technical skills, the certification
              emphasizes proactive security strategies, such as threat modeling,
              penetration testing, and incident response planning. By learning
              how to identify and mitigate potential vulnerabilities, security
              developers are prepared to design solutions that are resilient
              against evolving attack vectors. This approach helps organizations
              safeguard their data and maintain the trust of their users in an
              increasingly complex digital landscape.
              <br />
              <br />
              Completing this certification demonstrates a commitment to
              creating secure, high-performance applications. Whether you are
              looking to advance your career in software development or
              transition into a specialized role in cybersecurity, this
              certification equips you with the skills and knowledge to protect
              critical systems, reduce risks, and ensure compliance with
              industry standards and regulations.
            </p>
          </div>
          <div className="right">
            <img src={img} alt="thumb" loading="lazy" />
          </div>
        </div>
      </section>

      <style jsx>{`
        .intro-category-new {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 2rem;
          margin-top: 10px;
        }

        .intro-category-new .content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 2rem;
        }

        .intro-category-new .left {
          text-align: start;
          max-width: 800px;
        }

        .intro-category-new .left h1 {
          font-family: Inter;
          font-size: 36px;
          font-weight: 600;
          line-height: 43.57px;
          text-align: left;
          color: black;
        }

        .intro-category-new .left p {
          font-family: Inter;
          font-size: 16px;
          font-weight: 400;
          line-height: 19.36px;
          text-align: justify;
          color: #797979;
        }

        .intro-category-new .right img {
          height: auto;
          max-width: 100%;
        }

        @media (max-width: 1024px) {
          /* Tablet */
          .intro-category-new {
            padding: 1.5rem;
          }

          .intro-category-new .content {
            flex-direction: column;
            gap: 1rem;
          }

          .intro-category-new .left h1 {
            font-size: 28px;
            line-height: 36px;
          }

          .intro-category-new .left p {
            font-size: 14px;
          }
        }

        @media (max-width: 768px) {
          /* Mobile */
          .intro-category-new {
            padding: 1rem;
          }

          .intro-category-new .content {
            flex-direction: column;
            gap: 1rem;
          }
          .intro-category-new .right img {
            display: none;
          }
          .intro-category-new .left h1 {
            font-size: 24px;
            line-height: 32px;
          }

          .intro-category-new .left p {
            font-size: 14px;
          }
        }
      `}</style>
      <section className=" container">
        <div className="map-ui" data-test-label="curriculum-map">
          <h2 id="title-one" className='content-title-cards'>{t('landing.core-certs-heading')}</h2>
          <ul>
            {coreCurriculum.map((superBlock, i) => (
              <MapLi key={i} superBlock={superBlock} landing={false} />
            ))}
          </ul>

          <Spacer size="medium" />

          {showUpcomingChanges && (
            <>
              <h2 className="content-title-cards">
                {t("landing.upcoming-heading")}
              </h2>
              <ul>
                {superBlockOrder[SuperBlockStages.Upcoming].map(
                  (superBlock, i) => (
                    <MapLi key={i} superBlock={superBlock} landing={false} />
                  )
                )}
              </ul>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default DertificationDeveloppeurSecurite;
